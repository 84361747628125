import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import IdleStage from 'components/stages/mixer/IdleStage'
import ProcessingStage from 'components/stages/mixer/ProcessingStage'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import Processor, { MixerStrategy } from 'api/Retomagic'
import useFetchRequest from 'hooks/query/useFetchRequest'
import useFetchStyles from 'hooks/query/useFetchStyles'
import useFetchSuggestions from 'hooks/query/useFetchSuggestions'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { LimitError } from 'types/Response'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from '../GenerationControls/GenerationContainer/GenerationContainer'

import c from './NeuroMixer.module.scss'

function NeuroMixer() {
  const { t } = useTranslation()
  const { stage } = useAppSelector((state) => state.stage)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data: stylesData } = useFetchStyles()

  const { requestId } = useParams()

  const { data: requestData } = useFetchRequest({
    requestId,
  })

  const { data: suggestionsData } = useFetchSuggestions()
  const [seconds, setSeconds] = useState<number | undefined>(0)
  const [chosenStyle, setChosenStyle] = useState<string | undefined>('')
  const [generationProcessor, setGenerationProcessor] =
    useState<Processor | null>(null)

  const [text, setText] = useState<string>('')
  const [imageFile, setImageFile] = useState<File | null>(null)

  const getImageUrl = useCallback((file: File | null) => {
    return file ? URL.createObjectURL(file) : null
  }, [])

  const onImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        if (file.size / 1048576 > 30) {
          toast.error(t('error.uploadPhoto'))
          return
        }

        let isCheckSize = false
        const image = URL.createObjectURL(file)
        const img = new Image()

        img.onload = () => {
          if (img.width * img.height > 10000000) {
            URL.revokeObjectURL(image)
            setImageFile(null)
            isCheckSize = true
            toast.error(t('error.uploadPhoto'))
          }
        }
        img.src = image

        if (isCheckSize) return

        setImageFile(file)
      }
    },
    [],
  )

  const onDeleteImage = useCallback(() => {
    setImageFile(null)
  }, [])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, isFirstSucceededResult, id } = payload

      dispatch(changeProcessedAssets(assets))
      dispatch(changeStage('idle'))

      if (isFirstSucceededResult) {
        navigate(`/ai-avatars/finish/${id}`)
      }
    },
    [],
  )

  const onStyleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (chosenStyle === event.currentTarget.value) {
        setChosenStyle(undefined)
      } else {
        setChosenStyle(event.currentTarget.value)
      }
    },
    [chosenStyle],
  )

  const onExampleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setText(event.currentTarget?.value)
    },
    [],
  )

  const handleProcessingStart = useCallback(async () => {
    if (!imageFile) {
      toast.error(t('stages.generation.idle.uploadPhoto'))
      return
    }

    try {
      dispatch(changeStage('processing'))

      const thisStyle = stylesData.find((elem) => elem.id === chosenStyle)

      const strategy = new MixerStrategy({
        text,
        image: imageFile,
        styleTitle: thisStyle?.title,
        styleText: thisStyle?.text,
        requestId: undefined,
      })

      const processor = new Processor(strategy)
      processor.on(
        ProcessorEvents.PROCESSED_ASSETS_CHANGED,
        handleProcessedAssetsChanged,
      )

      await processor.start()
      setSeconds(strategy.getSeconds())
      setGenerationProcessor(processor)
    } catch (error) {
      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        setText('')
        setChosenStyle('')
        setImageFile(null)
        dispatch(changeStage('idle'))
      }
    }
  }, [text, handleProcessedAssetsChanged, chosenStyle, stylesData, imageFile])

  useEffect(() => {
    return () => {
      if (stage !== 'processing') {
        dispatch(changeProcessedAssets([]))
        dispatch(changeCurrentAssetIndex(0))
        generationProcessor?.stop()
        setGenerationProcessor(null)
      }
    }
  }, [generationProcessor, stage])

  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (requestData) {
      setText(requestData.assets[0].source)
      setChosenStyle(requestData.assets[0].settings?.style_title)
    }
  }, [requestData?.assets[0].status])

  return (
    <GenerationContainer stage={stage}>
      <MainSlot>
        {stage === 'processing' && seconds && (
          <div className={c.processing}>
            <ProcessingStage seconds={seconds} />
          </div>
        )}
        <Outlet />
      </MainSlot>
      <AsideSlot>
        <IdleStage
          text={text}
          imageFile={imageFile}
          inputRef={inputRef}
          getImageUrl={getImageUrl}
          onTextChange={setText}
          onProcessingStart={handleProcessingStart}
          suggestions={suggestionsData}
          onExampleClick={onExampleClick}
          onStyleClick={onStyleClick}
          onImageChange={onImageChange}
          chosenStyle={chosenStyle}
          styles={stylesData}
          onDeleteImage={onDeleteImage}
        />
      </AsideSlot>
    </GenerationContainer>
  )
}

export default NeuroMixer
