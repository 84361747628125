import React from 'react'
import { useTranslation } from 'react-i18next'

import AiAvatarsIcon from 'components/icons/AiAvatarIcon.svg'
import AiInpaintIcon from 'components/icons/AiInpaintIcon.svg'
import Dalle3Icon from 'components/icons/dalle3Icon.svg'
import EnhancementIcon from 'components/icons/EnhancePhotoIcon.svg'
import FaceRestorationIcon from 'components/icons/FaceRestorationIcon.svg'
import MagicVariationsIcon from 'components/icons/MagicVariationsIcon.svg'
import NeuroArtIcon from 'components/icons/NeuroArtIcon.svg'
import RemoveBackgroundIcon from 'components/icons/RemoveBackgroundIcon.svg'

function navigationModalItems() {
  const { t } = useTranslation()
  const ArtGenerationItems = [
    {
      id: 1,
      title: t('navigation.generationSpace'),
      icon: NeuroArtIcon,
      desc: t('navigation.info.neuroArt'),
      link: '/neuro-art',
    },
    // {
    //   id: 2,
    //   title: t('navigation.dalle3'),
    //   icon: Dalle3Icon,
    //   desc: t('navigation.info.dalle3'),
    //   link: '/dall-e-3',
    // },
    {
      id: 3,
      title: t('navigation.flux'),
      icon: Dalle3Icon,
      desc: t('navigation.info.flux'),
      link: '/flux',
    },
    {
      id: 4,
      title: t('navigation.magicVariations'),
      icon: MagicVariationsIcon,
      desc: t('navigation.info.magicVariations'),
      link: '/magic-variations',
    },
    // {
    //   id: 5,
    //   title: t('navigation.aiInpaint'),
    //   icon: AiInpaintIcon,
    //   desc: t('navigation.info.aiInpaint'),
    //   link: '/ai-inpaint',
    // },
    {
      id: 6,
      title: t('navigation.neuroMixer'),
      icon: AiAvatarsIcon,
      desc: t('navigation.info.neuroMixer'),
      link: '/ai-avatars',
    },
  ]

  const PhotoProcessingItems = [
    {
      id: 1,
      title: t('navigation.enhancement'),
      icon: EnhancementIcon,
      desc: t('navigation.info.enhancement'),
      link: '/enhancement',
    },
    {
      id: 2,
      title: t('navigation.restoration'),
      icon: FaceRestorationIcon,
      desc: t('navigation.info.faceRestoration'),
      link: '/face-restoration',
    },
    {
      id: 3,
      title: t('navigation.remover'),
      icon: RemoveBackgroundIcon,
      desc: t('navigation.info.removeBackground'),
      link: '/background-remover',
    },
  ]

  return {
    ArtGenerationItems,
    PhotoProcessingItems,
  }
}

export default navigationModalItems
