/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-alert */
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import { toast } from 'react-toastify'
import { ReactComponent as LeftIcon } from '@material-design-icons/svg/outlined/arrow_back_ios.svg'
import { ReactComponent as RightIcon } from '@material-design-icons/svg/outlined/arrow_forward_ios.svg'
import { ReactComponent as CopyIcon } from '@material-design-icons/svg/outlined/content_copy.svg'
import { ReactComponent as FavoriteBorderIcon } from '@material-design-icons/svg/outlined/favorite_border.svg'
import { ReactComponent as FavoriteIcon } from '@material-design-icons/svg/outlined/favorite.svg'
import { ReactComponent as CheckIcon } from '@material-design-icons/svg/round/check.svg'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// import { unlike } from 'api'
import {
  adminDeleteEverywhere,
  adminHidePost,
  adminUnHidePost,
  deletePost,
  likeRequest,
  unlikeRequest,
} from 'api'

import IconButton from 'components/common/IconButton'
import VerifiedIcon from 'components/VerifiedIcon'
import { setLogInModalOpen } from 'store/reducers/modalReducer'
import useFetchRequestMyProfile from 'hooks/query/useFetchRequestMyProfile'
import { useAppSelector } from 'hooks/store'
import useCopy from 'hooks/useCopy'
import useModal from 'hooks/useModal'
import { PostFeed } from 'types/Post'

import c from './Post.module.scss'

interface PostProps {
  post: PostFeed
  imgClassName?: string
  withLink?: boolean
  trending?: boolean
  postsData?: any
  currentPostId?: number
}

function Post({
  post,
  imgClassName = '',
  withLink = false,
  trending = false,
  postsData = undefined,
  currentPostId = undefined,
}: PostProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const queryClient = useQueryClient()

  const language = useAppSelector((state) => state.settings.language)
  const [isLiked, setIsLiked] = useState<boolean>(post.is_liked)
  const [likesCount, setLikesCount] = useState<number>(post.likes_count)
  const { isCopied, handleCopy } = useCopy(post.assets?.[0]?.source)

  const { data: user } = useFetchRequestMyProfile()

  const mutationLike = useMutation<void, Error>(() => {
    return likeRequest(post.id!)
  })
  const mutationUnLike = useMutation<void, Error>(() => {
    return unlikeRequest(post.id!)
  })
  const mutationDeleteByAdmin = useMutation<void, Error>(() => {
    return adminDeleteEverywhere(post.id!)
  })
  const mutationHide = useMutation<void, Error>(() => {
    return adminHidePost(post.id!)
  })
  const mutationUnhide = useMutation<void, Error>(() => {
    return adminUnHidePost(post.id!)
  })
  const mutationDelete = useMutation<void, Error>(() => {
    return deletePost(post.id!)
  })
  const { logInModalOpen } = useAppSelector((state) => state.modal)
  const { open: openLogInModal } = useModal(logInModalOpen, setLogInModalOpen)

  const copyIcon = useMemo(() => {
    return isCopied ? (
      <CheckIcon className={c.icon} style={{ fill: 'currentColor' }} />
    ) : (
      <CopyIcon className={c.icon} style={{ fill: 'currentColor' }} />
    )
  }, [isCopied])

  const likeHandler = () => {
    if (user?.id !== post.user.id) {
      if (isLiked) {
        setLikesCount((prev) => prev - 1)
        setIsLiked(false)
        mutationUnLike.mutateAsync().then(() => {
          queryClient.invalidateQueries(['post', post.id])
          queryClient.refetchQueries(['infinite-requests-trending'])
        })
      } else {
        setLikesCount((prev) => prev + 1)
        setIsLiked(true)
        mutationLike.mutateAsync().then(() => {
          queryClient.invalidateQueries(['post', post.id])
          queryClient.refetchQueries(['infinite-requests-trending'])
        })
      }
    } else {
      toast.error("You can't like your own post")
    }
  }

  const deleteEverywhere = () => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDeleteByAdmin.mutate()
      toast.success('Everything went well')
    }
  }

  const deleteYourPost = useCallback(() => {
    const result = window.confirm('Are you sure want to delete this post?')
    if (result) {
      mutationDelete.mutateAsync().then(() => {
        queryClient.invalidateQueries([`post`, id])
        queryClient
          .refetchQueries([`infinite-profilePosts-${user?.id}`])
          .then(() => {
            toast.success('Everything went well')
            navigate(`/profile/${user?.nickname}`)
          })
      })
    }
  }, [])

  const hideTrends = () => {
    const result = window.confirm('Are you sure want to hide this post?')
    if (result) {
      mutationHide.mutate()
      toast.success('Everything went well')
    }
  }

  const unhideTrends = () => {
    const result = window.confirm('Are you sure want to unhide this post?')
    if (result) {
      mutationUnhide.mutate()
      toast.success('Everything went well')
    }
  }

  return (
    <div className={c.post}>
      <div className={c.postTop}>
        <div className={c.postUser}>
          <div className={c.avatarWrapper}>
            {post.user.avatar && (
              <img className={c.avatar} src={post.user.avatar} alt="" />
            )}
          </div>
          <div className={c.nicknameWrapper}>
            <p className={c.nickname}>
              {post.user.nickname}{' '}
              {post.user.is_paid_subscription && <VerifiedIcon />}
            </p>
            {post.created_at && (
              <p className={c.date}>
                <ReactTimeAgo
                  date={new Date(post.created_at)}
                  locale={language === 'en' ? 'en-US' : 'ru'}
                />
              </p>
            )}
          </div>
          <NavLink
            className={c.link}
            to={`/profile/${post.user.nickname}`}
            end
          />
        </div>
        <div className={c.rightPart}>
          {user?.role === 'admin' && (
            <div className={c.admin}>
              <button
                type="button"
                className={c.delete}
                onClick={deleteEverywhere}
              >
                Delete everywhere
              </button>
              {post.user.is_trending_blocked ||
                (post.is_trending_blocked ? (
                  <button
                    type="button"
                    className={c.delete}
                    onClick={unhideTrends}
                  >
                    Unhide trends
                  </button>
                ) : (
                  <button
                    type="button"
                    className={c.delete}
                    onClick={hideTrends}
                  >
                    Hide trends
                  </button>
                ))}
            </div>
          )}

          {post.user.id === user?.id && (
            <div className={c.admin}>
              <button
                type="button"
                className={c.delete}
                onClick={deleteYourPost}
              >
                {t('profile.delete')}
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          trending || post.assets?.[0].source_type === 'video'
            ? c.postImgWrapperTrending
            : c.postImgWrapper
        } ${imgClassName}`}
      >
        {post.assets?.[0].source_type === 'video' && post.assets?.[0].url && (
          <>
            <video
              src={post.assets?.[0].url}
              className={c.postImg}
              controls
              loop
            />
            {/* <NavLink to={`/post/${post.id}`} end className={c.linkForVideo} /> */}
          </>
        )}
        {post.assets?.[0].source_type !== 'video' && post.assets?.[0].url && (
          <>
            <img className={c.postImg} src={post.assets?.[0].url} alt="" />
            <NavLink to={`/trending-post/${post.id}`} end className={c.link} />
          </>
        )}
      </div>
      <div className={c.postFeedBack}>
        <div className={c.leftPart}>
          <button
            type="button"
            className={c.leftArrow}
            style={{ display: trending ? 'none' : 'flex' }}
          >
            <LeftIcon className={c.icon} />
            {postsData?.[currentPostId! - 1]?.id && (
              <NavLink
                to={`/post/${postsData?.[currentPostId! - 1]?.id}`}
                end
                className={c.link}
                state={{
                  postsData,
                  index: currentPostId! - 1,
                }}
              />
            )}
          </button>
          <div
            className={c.postLikes}
            onClick={!user?.id ? openLogInModal : likeHandler}
            onKeyDown={!user?.id ? openLogInModal : likeHandler}
            role="button"
            tabIndex={0}
          >
            <div className={c.postLikesIcon}>
              <FavoriteBorderIcon />
              <div className={`${c.favoredIcon} ${isLiked ? c.favored : ''}`}>
                <FavoriteIcon />
              </div>
            </div>
            <p className={c.postLikesCount}>{likesCount}</p>
          </div>
        </div>
        <div className={c.rightPart}>
          <div className={c.postViews}>
            {`${post.views_count} ${t('profile.views', {
              count: Number(post.views_count),
            })}`}
          </div>
          <button
            type="button"
            className={c.rightArrow}
            style={{ display: trending ? 'none' : 'flex' }}
          >
            <RightIcon className={c.icon} />
            {postsData?.[currentPostId! + 1]?.id && (
              <NavLink
                to={`/post/${postsData?.[currentPostId! + 1]?.id}`}
                end
                className={c.link}
                state={{
                  postsData,
                  index: currentPostId! + 1,
                }}
              />
            )}
          </button>
        </div>
      </div>
      <div className={c.postBottom}>
        {post.assets?.[0]?.source_type !== 'image' && (
          <>
            <div className={c.text}>{post.assets?.[0]?.source}</div>
            <IconButton
              className={c.postCopy}
              disabled={isCopied}
              onClick={handleCopy}
            >
              {copyIcon}
            </IconButton>
          </>
        )}
      </div>
      <div className={c.tagContainer}>
        {post.handler === 'William_Henry_Harrison' && (
          <div className={c.relatedDalle3}>{t('feed.dalle3')}</div>
        )}
        {post.handler === 'John_Tyler' && (
          <div className={c.relatedDalle3}>{t('feed.flux')}</div>
        )}
        {post.assets?.[0]?.style && post.assets?.[0]?.style !== 'undefined' && (
          <span className={c.textStyle}>{post.assets?.[0]?.style}</span>
        )}
      </div>
    </div>
  )
}

export default Post
