import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ResolutionType } from 'types/Response'

import c from './ResolutionSelectionFixedValues.module.scss'

interface ResolutionSelectionFixedValuesProps {
  resolution?: number | null
  setResolution: (value: number) => void
  fixedResolutions?: ResolutionType[]
}

function ResolutionSelectionFixedValues({
  resolution = null,
  setResolution,
  fixedResolutions = undefined,
}: ResolutionSelectionFixedValuesProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(resolution)
  const { t } = useTranslation()

  const handleClick = useCallback(
    (index: number) => {
      setResolution(index)
      setActiveIndex(index)
    },
    [setResolution],
  )

  return (
    <div className={c.powerSelection}>
      <p className={c.heading}>{t('stages.generation.idle.resolution')}</p>
      <div className={c.resolutionContainer}>
        {fixedResolutions?.map((item, index) => (
          <button
            type="button"
            onClick={() => handleClick(index)}
            key={item.id}
            className={classNames({
              [c.resolutionButton]: true,
              [c.isActive]: activeIndex === index,
            })}
          >
            {item.width && item.height && `${item.width}x${item.height}`}
            {item.aspectRatio && `${item.aspectRatio}`}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ResolutionSelectionFixedValues
