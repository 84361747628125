import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import CommentsPage from 'pages/CommentsPage'
import DallE3 from 'components/DallE3'
import FluxUltra from 'components/FluxUltra'
import Generation from 'components/Generation'
import MagicVariations from 'components/MagicVariations'
import NeuroMixer from 'components/NeuroMixer'
import VideoArt from 'components/VideoArt'
// import AiInpaint from 'components/AiInpaint'
import { changeStage } from 'store/reducers/stageReducer'
import Handlers from 'types/Handlers'
import MobileHeader from '../../components/MobileHeader'

export default function GenerationPage({
  handler,
}: {
  handler: `${Handlers}`
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [handlerState, setHandlerState] = useState(handler)
  const [heading, setHeading] = useState<string>()

  useEffect(() => {
    switch (handlerState) {
      case 'Andrew_Jackson':
        setHeading(t('navigation.neuroMixer'))
        break
      case 'George_Washington':
        setHeading(t('navigation.generation'))
        break
      case 'Thomas_Jefferson':
        setHeading(t('navigation.magicVariations'))
        break
      case 'James_Monroe':
        setHeading(t('navigation.videoArt'))
        break
      case 'William_Henry_Harrison':
        setHeading(t('navigation.dalle3'))
        break
      case 'John_Tyler':
        setHeading(t('navigation.flux'))
        break
      // case 'Martin_Van_Buren':
      //   setHeading(t('navigation.aiInpaint'))
      //   break

      case 'James_Madison':
        setHeading(t('navigation.JamesMadison'))
        break
      default:
        setHeading('')
    }
  }, [handlerState])

  useEffect(() => {
    dispatch(changeStage('idle'))
    setHandlerState(handler)
  }, [handler])

  return (
    <>
      <MobileHeader heading={heading} isOnGoBack />
      {handlerState === 'Andrew_Jackson' && <NeuroMixer />}
      {handlerState === 'George_Washington' && <Generation />}
      {handlerState === 'Thomas_Jefferson' && <MagicVariations />}
      {handlerState === 'James_Monroe' && <VideoArt />}
      {/* {handlerState === 'William_Henry_Harrison' && <DallE3 />} */}
      {handlerState === 'John_Tyler' && <FluxUltra />}
      {/* {handlerState === 'Martin_Van_Buren' && <AiInpaint />} */}
      {handlerState === 'James_Madison' && <CommentsPage />}
    </>
  )
}
