/* eslint-disable import/prefer-default-export */

export const fixedResolutions = [
  {
    id: 1,
    width: 1024,
    height: 1536,
  },
  {
    id: 2,
    width: 1536,
    height: 1536,
  },
  {
    id: 3,
    width: 1536,
    height: 1024,
  },
]

export const fixedResolutionsDalle3 = [
  {
    id: 1,
    width: 1024,
    height: 1792,
  },
  {
    id: 2,
    width: 1024,
    height: 1024,
  },
  {
    id: 3,
    width: 1792,
    height: 1024,
  },
]

export const fixedResolutionsFlux = [
  {
    id: 1,
    aspectRatio: '21:9',
  },
  {
    id: 2,
    aspectRatio: '16:9',
  },
  {
    id: 3,
    aspectRatio: '4:3',
  },
  {
    id: 4,
    aspectRatio: '1:1',
  },
  {
    id: 5,
    aspectRatio: '3:4',
  },
  {
    id: 6,
    aspectRatio: '9:16',
  },
  {
    id: 7,
    aspectRatio: '9:21',
  },
]
