import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Img } from 'react-image'
import { NavLink } from 'react-router-dom'
import { TelegramShareButton, VKShareButton } from 'react-share'
import { toast } from 'react-toastify'
import { CSSTransition } from 'react-transition-group'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'

import LightBox from 'components/LightBox'
import { deleteRequest } from '../../api'
import useFetchRequest from '../../hooks/query/useFetchRequest'
import { BaseResponse } from '../../types/Response'
import Loader from '../common/Loader'
import DeleteIcon from '../icons/DeleteIcon'
import FullScreenIcon from '../icons/FullScreenIcon'
import LinkIcon from '../icons/LinkIcon'
import SocialShareIcon from '../icons/SocialShareIcon'
import TelegramIcon from '../icons/TelegramIcon'
import VkIcon from '../icons/VkIcon'
import SaveButton from '../SaveButton'

import c from './ArchiveCard.module.scss'

interface ArchiveCardProps {
  asset: BaseResponse
  index: number
  data: BaseResponse
  isImageLoaded: { [key: number]: boolean }
  handleImageLoaded: (id: number) => void
  isDeleting: boolean
  selectedToDelete: number[]
  isVideo?: boolean
  isChecked: boolean
  setIsDeleting: (value: boolean | ((prevValue: boolean) => boolean)) => void
  selectToDelete: (id: number) => void
  handleCheckboxChange: (id: number, idData: number) => void
  isImg?: boolean
  archiveData: BaseResponse[]
  isSelected: boolean
}

export default function ArchiveCard({
  isImg = false,
  isDeleting,
  setIsDeleting,
  data,
  isChecked,
  handleCheckboxChange,
  selectedToDelete,
  isVideo = false,
  isImageLoaded,
  selectToDelete,
  handleImageLoaded,
  archiveData,
  asset,
  index,
  isSelected,
}: ArchiveCardProps) {
  const { t } = useTranslation()

  const [isSharedPhoto, setIsSharedPhoto] = useState<boolean>(false)

  const { data: arch } = useFetchRequest({ requestId: data.id.toString() })

  const assetContainerStyle = isChecked ? { backgroundColor: '#24222F' } : {}

  const mutationDelete = useMutation<void, Error>(() => {
    return deleteRequest(data.id)
  })

  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const queryClient = useQueryClient()

  const handleFullScreenOpen = useCallback(() => {
    setIsLightboxOpen(true)
  }, [])

  const handleFullScreenClose = useCallback(() => {
    setIsLightboxOpen(false)
  }, [])

  const shareSocialHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setIsSharedPhoto(!isSharedPhoto)
    },
    [isSharedPhoto],
  )

  const deleteYourPost = useCallback(() => {
    const result = window.confirm(
      'Are you sure want to delete this archive post?',
    )
    if (result) {
      mutationDelete.mutateAsync().then(() => {
        queryClient.invalidateQueries([`archiveData`, asset.id])
        queryClient.refetchQueries([`infinite-requests`]).then(() => {
          toast.success('Everything went well')
        })
      })
    }
  }, [])

  const shareUrl = `${asset.assets[0].url}` || 'defaultImageUrl'

  const handlerRoutes = {
    George_Washington: '/enhancement/finish/',
    Thomas_Jefferson: '/face-restoration/finish/',
    James_Monroe: '/background-remover/finish/',
    William_Henry_Harrison: '/dall-e-3/finish/',
    John_Adams: '/neuro-art/finish/',
    Andrew_Jackson: '/ai-avatars/finish/',
    James_Madison: '/magic-variations/finish/',
    John_Quincy_Adams: '/video-art/finish/',
    Martin_Van_Buren: '/ai-inpaint/finish/',
    John_Tyler: '/flux/finish/',
  }

  const routePath = arch ? handlerRoutes[arch.handler] : null
  const linkPath = routePath ? `${routePath}${data.id}` : '#'

  return (
    <div
      className={classNames({
        [c.assetContainer]: true,
        [c.fullScreen]: isLightboxOpen,
      })}
      style={assetContainerStyle}
    >
      <div className={c.assetImageWrapper}>
        {!isDeleting ? (
          <NavLink
            className={c.link}
            end
            to={linkPath}
            state={{ postsData: archiveData, index }}
          >
            {asset.assets[0].source_type === 'video' ? (
              <video src={asset.assets[0].url} className={c.assetImage} muted />
            ) : (
              <Img
                src={
                  asset.assets[0].thumbnail_url ||
                  asset.assets[0].url ||
                  'default'
                }
                className={c.assetImage}
                loader={<Loader />}
                onLoad={() => {
                  handleImageLoaded(asset.id)
                }}
              />
            )}
          </NavLink>
        ) : (
          <div className={c.link}>
            {asset.assets[0].source_type === 'video' ? (
              <video src={asset.assets[0].url} className={c.assetImage} muted />
            ) : (
              <Img
                src={
                  asset.assets[0].thumbnail_url ||
                  asset.assets[0].url ||
                  'default'
                }
                className={c.assetImage}
                loader={<Loader />}
                onLoad={() => {
                  handleImageLoaded(asset.id)
                }}
              />
            )}
          </div>
        )}
        {asset.assets[0].status !== 'success' && <Loader />}
        <CSSTransition
          in={isSharedPhoto}
          timeout={100}
          classNames={{
            enter: c.settingsEnter,
            enterActive: c.settingsEnterActive,
            exit: c.settingsExit,
            exitActive: c.settingsExitActive,
          }}
          mountOnEnter
          unmountOnExit
        >
          <div className={c.socialNetworksContainer}>
            <div className={c.socials}>
              <TelegramShareButton
                title={t('socials.shareTitle')}
                url={shareUrl}
              >
                <TelegramIcon className={c.iconSocial} />
              </TelegramShareButton>
              <VKShareButton url={shareUrl}>
                <VkIcon className={c.iconSocial} />
              </VKShareButton>
            </div>
            <LinkIcon className={c.iconSocial} />
          </div>
        </CSSTransition>
        <button
          type="button"
          onClick={handleFullScreenOpen}
          className={c.wrapper}
        >
          {isImageLoaded[asset.id] && (
            <div className={c.fullScreenContainer}>
              <div className={c.iconSetting}>
                <FullScreenIcon className={c.iconSetting} />
              </div>
            </div>
          )}
        </button>
      </div>
      {isLightboxOpen && (
        <LightBox
          imageUrl={asset.assets[0].url || 'default'}
          onCloseRequest={handleFullScreenClose}
        />
      )}
      <div className={c.archiveTag}>
        {arch?.handler === 'George_Washington' && (
          <div className={c.textStyle}>
            <span className={c.texts}>{t('navigation.enhancement')} </span>
          </div>
        )}
        {arch?.handler === 'Thomas_Jefferson' && (
          <div className={c.textStyle}>
            <span className={c.texts}>{t('navigation.restoration')}</span>
          </div>
        )}
        {arch?.handler === 'James_Monroe' && (
          <div className={c.textStyle}>
            <span className={c.texts}>{t('navigation.remover')}</span>
          </div>
        )}
        {arch?.handler === 'William_Henry_Harrison' && (
          <div className={c.textStyle}>
            <span className={c.texts}>{t('navigation.dalle3')}</span>
          </div>
        )}
        {arch?.handler === 'John_Quincy_Adams' && (
          <div className={c.textStyle}>
            {' '}
            <span className={c.texts}>{t('navigation.videoArt')}</span>
          </div>
        )}
        {arch?.handler === 'John_Adams' && (
          <div className={c.textStyle}>
            <span className={c.texts}>{t('navigation.generationSpace')}</span>
          </div>
        )}
        {arch?.handler === 'James_Madison' && (
          <div className={c.textStyle}>
            <span className={c.texts}>{t('navigation.magicVariations')}</span>
          </div>
        )}
        {arch?.handler === 'Andrew_Jackson' && (
          <div className={c.textStyle}>
            <span className={c.texts}>{t('navigation.neuroMixer')}</span>
          </div>
        )}
      </div>
      <div className={c.assetActions}>
        <div className={c.part}>
          <input
            type="checkbox"
            className={c.checkbox}
            onChange={() => handleCheckboxChange(asset.id, data.id)}
            checked={isChecked}
          />

          <button
            type="button"
            disabled={asset.assets[0].status !== 'success'}
            onClick={shareSocialHandler}
            className={c.shareAction}
          >
            <SocialShareIcon />
            {t('general.buttons.share')}
          </button>
        </div>
        <div className={c.part}>
          <div className={c.cardAct}>
            <SaveButton className={c.actions} imageUrl={asset.assets[0].url} />
          </div>
          <button
            type="button"
            disabled={asset.assets[0].status !== 'success'}
            onClick={deleteYourPost}
            className={c.actions}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
      {isSelected && (
        <button
          className={c.selectWrapperButton}
          type="button"
          onClick={() => handleCheckboxChange(asset.id, data.id)}
        />
      )}
    </div>
  )
}
