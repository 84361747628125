import { toast } from 'react-toastify'
import { postRepeat, postRequestFluxUltra } from 'api'

import { RequestGeneration } from 'types/FormDataObject'
import Handlers from 'types/Handlers'
import { BaseResponse, LimitError } from 'types/Response'
import Strategy from './Strategy'

export default class FluxUltraStrategy extends Strategy {
  private readonly text: string

  private styleTitle?: string

  private styleText?: string

  private numSteps?: number

  private aspectRatio?: string

  private handler: `${Handlers}` = Handlers.JOHN_TYLER

  private requestId?: string

  private responseSeconds?: number

  constructor(
    request: string,
    styleTitle: string | undefined,
    styleText: string | undefined,
    aspectRatio: string,
    requestId?: string,
  ) {
    super()
    this.text = request
    this.requestId = requestId
    this.styleTitle = styleTitle
    this.styleText = styleText
    this.aspectRatio = aspectRatio
  }

  public setHandler(handler: `${Handlers}`) {
    this.handler = handler
    return this
  }

  public getSeconds() {
    return this.responseSeconds || 30
  }

  public async start(): Promise<void> {
    const { id, assets, meta } = await this.makeRequest()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async repeat(): Promise<void> {
    const { id, assets, meta } = await this.makeRequestRepeat()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      const requestData: RequestGeneration = {
        text: this.text,
      }
      if (this.styleTitle) requestData.styleTitle = this.styleTitle
      if (this.aspectRatio) requestData.aspect_ratio = this.aspectRatio
      if (this.styleText) requestData.styleText = this.styleText

      return await postRequestFluxUltra(requestData)
    } catch (error: LimitError | unknown) {
      this.collector?.stop()

      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw errorData
      }

      toast.error('Something went wrong, try again!')
      throw error
    }
  }

  public async makeRequestRepeat(): Promise<BaseResponse> {
    try {
      return await postRepeat(this.requestId!, this.text)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()

      throw error
    }
  }
}
