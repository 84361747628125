import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import IdleStage from 'components/stages/enhancement/IdleStage'
import ProcessingStage from 'components/stages/enhancement/ProcessingStage'
import {
  changeCurrentAssetIndex,
  changeProcessedAssets,
  changeStage,
} from 'store/reducers/stageReducer'
import Processor, { EnhanceStrategy } from 'api/Retomagic'
import useInfiniteFetchRequests from 'hooks/query/useInfiniteFetchRequests'
import { useAppDispatch, useAppSelector } from 'hooks/store'
import Handlers from 'types/Handlers'
import { OutputFormat } from 'types/OutputFormat'
import {
  ProcessedAssetsChangedPayload,
  ProcessorEvents,
} from 'types/ProcessorEvents'
import { HANDLER_OPTIONS } from 'config/handlers'
import { postPost } from '../../api'
import EnhancementCard from '../../components/EnhancementCard'
import GenerationContainer, {
  AsideSlot,
  MainSlot,
} from '../../components/GenerationControls/GenerationContainer/GenerationContainer'
import MobileHeader from '../../components/MobileHeader'
import useFetchRequestMyProfile from '../../hooks/query/useFetchRequestMyProfile'
import { BaseAsset, LimitError } from '../../types/Response'

import c from './EnhancementPage.module.scss'

function EnhancementPage({ handler }: { handler: `${Handlers}` }) {
  const dispatch = useAppDispatch()
  const { stage } = useAppSelector((state) => state.stage)

  const navigate = useNavigate()
  const [seconds, setSeconds] = useState<number>(0)
  const [outputFormat, setOutputFormat] = useState<OutputFormat>('jpeg')
  const [imageFiles, setImageFiles] = useState<File[]>([])

  const [cards, setCards] = useState<Array<Array<BaseAsset>>>([])

  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] =
    useState<boolean>(false)

  const { data, isLoading } = useInfiniteFetchRequests({
    sourceType: 'image',
    handler,
  })

  const historyItems = useMemo(() => {
    if (!data) return []
    return data.pages.flatMap((p) => p)
  }, [data, handler])

  useEffect(() => {
    const uniquePostIds = new Set()

    const items: BaseAsset[] = []
    historyItems.forEach((item) => {
      items.push(...item.assets)
    })

    const arr: BaseAsset[][] = []

    items.forEach((elem: BaseAsset, index: number) => {
      if (!uniquePostIds.has(elem.id)) {
        uniquePostIds.add(elem.id)

        if (!arr[index % 2]) arr[index % 2] = []

        arr[index % 2].push(elem)
      }
    })

    setCards(arr)
  }, [historyItems])

  const [isShared, setIsShared] = useState<{ [key: number]: boolean }>({})
  const [forSubs, setForSubs] = useState<boolean>(false)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [isImageLoaded, setIsImageLoaded] = useState<{
    [key: number]: boolean
  }>(historyItems.reduce((acc, asset) => ({ ...acc, [asset.id]: false }), {}))

  const [heading, setHeading] = useState<string>('')

  const { ids } = useParams()

  const { t } = useTranslation()

  const location = useLocation()

  useEffect(() => {
    switch (handler) {
      case 'Thomas_Jefferson':
        setHeading(t('navigation.restoration'))
        break
      case 'George_Washington':
        setHeading(t('navigation.enhancement'))
        break
      case 'James_Monroe':
        setHeading(t('navigation.remover'))
        break
      default:
        setHeading('nothing')
    }
  }, [handler])

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false)
      dispatch(changeStage('idle'))
    }
  }, [])

  const handleImageLoaded = useCallback((assetId: number) => {
    setIsImageLoaded((prevState) => ({ ...prevState, [assetId]: true }))
  }, [])

  const imageUrls = useMemo(() => {
    if (imageFiles) {
      return imageFiles.map((file) => URL.createObjectURL(file))
    }
    return null
  }, [imageFiles])

  useEffect(() => {
    if (imageFiles.length > 0) {
      dispatch(changeStage('preview'))
    }
    if (imageFiles.length === 0) {
      dispatch(changeStage('idle'))
    }
  }, [imageFiles])

  const handleProcessedAssetsChanged = useCallback(
    (payload: ProcessedAssetsChangedPayload) => {
      const { assets, finish, id } = payload

      dispatch(changeProcessedAssets(assets))

      if (finish) {
        dispatch(changeStage('idle'))
        navigate(`${location.pathname}/finish/${id}`)
      }
    },
    [],
  )

  const { data: user } = useFetchRequestMyProfile()

  const queryClient = useQueryClient()

  const handleProcessingStart = useCallback(async () => {
    try {
      if (imageFiles.length <= 0) return

      dispatch(changeStage('processing'))

      const strategy = new EnhanceStrategy(imageFiles)
        .setHandler(handler)
        .setOutputFormat(outputFormat)

      const processor = new Processor(strategy)
      processor.on(
        ProcessorEvents.PROCESSED_ASSETS_CHANGED,
        handleProcessedAssetsChanged,
      )

      await processor.start()
      setSeconds(strategy.getSeconds()!)
    } catch (error: LimitError | unknown) {
      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        setImageFiles([])
        setOutputFormat('jpeg')
        dispatch(changeStage('idle'))
      }
    }
  }, [imageFiles, handler, outputFormat, handleProcessedAssetsChanged])

  useEffect(() => {
    if (stage !== 'idle') return
    setImageFiles([])
    dispatch(changeCurrentAssetIndex(0))
    dispatch(changeProcessedAssets([]))
  }, [stage])

  const mutationShare = useMutation<void, Error, number>((assetId) => {
    return postPost({ assets: [assetId!], subscribers_only: forSubs })
  })

  const shareHandler = (assetId: number) => {
    mutationShare
      .mutateAsync(assetId)
      .then(() => {
        queryClient.invalidateQueries([`request`, ids])
        queryClient.refetchQueries([`infinite-profilePosts-${user?.id}`])
      })
      .then(() => {
        setIsShared((prev) => ({ ...prev, [assetId]: true }))
        toast.success('Everything went well')
      })
  }

  return (
    <GenerationContainer stage={stage} isLoading={isLoading}>
      <MainSlot>
        {stage === 'processing' && (
          <div className={c.processing}>
            <ProcessingStage seconds={seconds!} />
          </div>
        )}

        {stage !== 'processing' && !location.pathname.includes('finish') && (
          <div className={c.cardsContainer}>
            {cards.map((container, containerIndex) => (
              <div
                className={c.secondColumn}
                key={`${new Date(containerIndex).getTime().toString()}`}
              >
                {container.map((asset, assetIndex) => (
                  <EnhancementCard
                    key={`${new Date(assetIndex).getTime().toString()}`}
                    isImageLoaded={isImageLoaded}
                    handleImageLoaded={handleImageLoaded}
                    imageUrls={imageUrls}
                    asset={asset}
                    index={assetIndex}
                    isShared={isShared}
                    shareHandler={shareHandler}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
        <Outlet />
      </MainSlot>
      <AsideSlot>
        <MobileHeader heading={heading} isOnGoBack />
        <IdleStage
          imageUrls={imageUrls!}
          outputFormat={outputFormat}
          onOutputFormatChange={setOutputFormat}
          onProcessingStart={handleProcessingStart}
          isAdvancedSettingsOpen={isAdvancedSettingsOpen}
          setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          handler={handler}
          handlerOptions={HANDLER_OPTIONS}
          setImageFiles={setImageFiles}
        />
      </AsideSlot>
    </GenerationContainer>
  )
}

export default EnhancementPage
